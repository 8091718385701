<template>
  <div class="infinite-list" style="width: 100%;padding-top: 70px">
    <div class="content-box mt-3 mb-3 pr">
      <div class="back-btn tac cp" @click="goback()">
        <i class="iconfont icon-fanhui"></i>
        <div class="mt-2">返回</div>
      </div>
      <div class="bs p-3" style="width: 100%;background: #ffffff">
        <!--        <el-page-header @back="goback()">-->
        <!--          <span slot="content" style="font-weight:bold">服务详情</span>-->
        <!--        </el-page-header>-->
        <div>
          <div v-loading="loading" class="wi100 mt-4">
            <div class="uf pr service-main">
              <div>
                <div class="company-box uf uf-ac mb-2">
                  <div class="icon-box mr-2 uf uf-ac uf-pc">
                    <i class="iconfont icon-company-fill"></i>
                  </div>
                  <div class="fwb f20">{{dataForm.enterpriseName}}</div>
                </div>
                <div class="service-image">
                  <el-image :src="dataForm.itemUrl ? $cons.SERVER_PATH + dataForm.itemUrl : ''" fit="cover">
                    <div slot="error">
                      <div  class="error-img-wrapper">
                        <div>{{dataForm.serviceName && dataForm.serviceName.length > 16 ? dataForm.serviceName.substr(0,15) + '...' : dataForm.serviceName}}</div>
                      </div>
                    </div>
                  </el-image>
                  <!--                <img class="logo" width="300px" height="220px" :onerror="src='../../asserts/img/product/default_1.png;this.onerror=null'" :src="dataForm.itemUrl ? $cons.SERVER_PATH + dataForm.itemUrl : require('@/assets/img/zwtp.png')"/>-->
                </div>
                <div class="wi100 uf uf-ac uf-pa">
                  <div>
                    <el-button v-if="collection" type="text" :icon="'el-icon-star-off'"
                               @click="collectionControl">收藏产品
                    </el-button>
                    <el-button v-else type="text" :icon="'el-icon-star-on'" @click="removeCollect">已收藏
                    </el-button>
                  </div>
                  <div class="f14 op-07">
                    <span>累计销量：</span><span>{{dataForm.serviceCount}}</span>
                  </div>
                  <div class="f14 op-07">
                    <span>累计评价：</span><span>{{dataForm.itemComment}}</span>
                  </div>
                </div>
              </div>
              <div class="detail pl-3" style="width: 968px">
                <div class="wrap">
                  <div class="title f22 fwb">
                    {{dataForm.serviceName}}
                  </div>
                  <div class="uf uf-ac">
                    <div class="c-base mt-2">{{dataForm.itemDesc}}</div>
                  </div>
                  <div class="price uf-ac mt-3">
                    <span class="fwb ufc-11 f30"
                          v-if="!this.priceVisible && this.priceList.min!==this.priceList.max">
                        ￥{{(this.priceList.min || 0).toFixed(2)}}-{{(this.priceList.max || 0).toFixed(2)}}
                      <span style="margin-left: 16px;font-size: 12px;font-weight: normal;opacity: 60%;color: #8c939d">{{priceTypeName}}</span>
                      </span>
                    <span class="fwb ufc-11 f30"
                          v-else-if="!this.priceVisible && this.priceList.min===this.priceList.max">
                        ￥{{(this.priceList.min || 0).toFixed(2)}}
                      <span style="margin-left: 16px;font-size: 12px;font-weight: normal;opacity: 60%;color: #8c939d">{{priceTypeName}}</span>
                      </span>
                    <span class="fwb ufc-11 f30" v-else-if="this.discountProductPrice === -1 && this.price !=-1">
                        ￥{{(this.price || 0).toFixed(2)}}
                      <span style="margin-left: 16px;font-size: 12px;font-weight: normal;opacity: 60%;color: #8c939d">{{priceTypeName}}</span>
                      </span>
                    <span class="fwb ufc-11 f30" v-else-if="this.discountProductPrice === -1 && this.price ==-1">
                        面议
                      </span>
                    <span class="fwb ufc-11 f30" style="color: red" v-else>
                          <i style="text-decoration:line-through;opacity: 0.8;color: gray">￥{{(this.price || 0).toFixed(2)}}</i>￥{{(this.discountProductPrice || 0).toFixed(2)}}
                      <span style="margin-left: 16px;font-size: 12px;font-weight: normal;opacity: 60%;color: #8c939d">{{priceTypeName}}</span>
                        </span>
                    <!--                    <div class="amount uf mt-3 uf-ac">-->
                    <!--                      <span class="label" style="line-height: 40px">承诺：</span>-->
                    <!--                      <div class="f14 uf-f1 uf">-->
                    <!--                        <div class="uf uf-ac mr-3">-->
                    <!--                          <img src="../../assets/img/duigou.png"/>-->
                    <!--                          <span style="margin-left:8px">明码标价</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="uf uf-ac mr-3">-->
                    <!--                          <img src="../../assets/img/duigou.png"/>-->
                    <!--                          <span style="margin-left:8px">严选</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="uf uf-ac">-->
                    <!--                          <img src="../../assets/img/duigou.png"/>-->
                    <!--                          <span style="margin-left:8px">服务质保</span>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                  <div style="padding-left: 20px">
                    <div class="mt-3" style="line-height: 40px">
                      <div class="label">服务地区：</div>
                      <div class="f14 mr-3" style="padding-left: 70px">
                        <el-cascader
                            v-model="attarArea"
                            :options="attarAreaList"
                            ref="tree"
                            style="width: 270px"
                            :props="{ value: 'orgAllName', label: 'orgName' }"
                            @change="handleChange">
                        </el-cascader>
                        <span class="areaErrMsg" v-if="attarArea.length === 0">请选择服务地区</span>
                      </div>
                    </div>
                    <div>
                      <template v-for="(ProductItem, n) in simulatedDATA.specifications">
                        <div class="mt-2 abc" style="line-height: 35px;margin-bottom: 3px" :key="n" v-if="n > 0">
                          <div class="label">{{ProductItem.name}}：</div>
                          <div class="f14 mr-3 product-footerlist" style="padding-left: 70px;width: 800px">
                            <div
                                v-for="(oItem,index) in ProductItem.item"
                                :key="index"
                                style="float: left;position: relative;margin-right: 7px;margin-bottom: 4px;color: #606266;"
                            >
                              <b></b>
                              <el-tooltip effect="light" :content="oItem.name" placement="top" :disabled="tooltipVisible[oItem.name + '-' + index] === undefined || tooltipVisible[oItem.name + '-' + index] === false">
                                <div
                                    class="p-name"
                                    @click="specificationBtn(oItem.name, n, index)"
                                    @mouseenter="pNameVisible($event, oItem.name, index)"
                                    @mouseleave="tooltipVisible[oItem.name + '-' + index] = false"
                                    :class="[oItem.isShow ? '' : 'noneActive', subIndex[n] === index ? 'productActive' : '']"
                                >
                                  {{ oItem.name }}
                                </div>
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="mt-2" style="line-height: 40px">
                      <div class="label">购买数量：</div>
                      <div class="f14 mr-3" style="padding-left: 70px">
                        <el-input-number size="small" v-model="count" :min="1" controls-position="right"></el-input-number>
                      </div>
                    </div>
                  </div>
                  <div class="btns uf mt-3">
                    <el-button icon="el-icon-service" v-if="dataForm.serviceMethod === '0' || dataForm.serviceMethod === '2'" @click="addOrFollow" round>咨询客服</el-button>
                    <template v-if="dataForm.serviceMethod === '0' || dataForm.serviceMethod === '1'">
                      <el-button type="warning" @click="purchase()" :disabled="payVisible" round>立即购买</el-button>
                      <el-button type="primary" icon="iconfont icon-gouwuche mr-2" @click="toShoppingCart" :disabled="payVisible" round>加入购物车</el-button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-row class="mt-3" :gutter="15">
          <el-col :span="19">
            <el-tabs type="card" class="box-card-nav" v-model="active" >
              <el-tab-pane label="服务详情" name="detail">
                <div class="service-details-img" >
                  <img :src="$cons.SERVER_PATH + this.dataForm.descUrl" alt="">
                </div>
              </el-tab-pane>
              <el-tab-pane label="用户评论"  name="comment">
                <service-comment :id="this.dataForm.id" v-if="active==='comment'" ></service-comment>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="5">
            <el-card class="box-card mt-2" shadow="never" style="margin-top: -1px">
              <div slot="header" class="uf uf-ac">
                <i class="iconfont icon-cainixihuan c-base mr-2"></i>
                <span class="fwb">猜你喜欢</span>
              </div>
              <div class="uf uf-pc">
                <div class="ad-aside uf-f1" style="width: 160px;padding: 0">
                  <div>
                    <div class="ad-list" v-for="(item, index) in recommendList" :key="index">
                      <div class="pic-box " @click="initDetail(item.id)">
                        <el-image class="ad-image" :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')">
                          <div class="wi100" slot="error">
                            <div class="ad-error tal">
                              <div>{{item.serviceName && item.serviceName.length > 16 ? item.serviceName.substr(0,15) + '...' : item.serviceName}}</div>
                            </div>
                          </div>
                        </el-image>
                        <!--                        <img-->
                        <!--                          :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>-->
                      </div>
                      <span v-if="item.minPrice === -1" class="f20" style="color: #fe3b25;">面议</span>
                      <div v-else style="color:#DC143C;">￥{{(item.minPrice || 0).toFixed(2)}}</div>
                      <div @click="initDetail(item.id)">
                        <span style="font-size:13px;">{{ item.serviceName }}</span>
                      </div>
                      <div style="padding: 0 14px">
                        <div class="f12">
                          <span class="op-07">服务分类：{{ item.itemName }}</span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-divider><span style="font-size: 16px;font-weight: bold">最近浏览</span></el-divider>
        <el-row>
          <el-col>
            <div class="li-box uf uf fwrap-w tal" style="margin-left: -10px">
              <template v-if="viewList.length<5">
                <div class="li" v-for="(item, index) in viewList" :key="index">
                  <div class="p-box" @click="initDetail(item.serviceId)">
                    <el-image class="ad-image" :src="$cons.SERVER_PATH + item.logoUrl">
                      <div class="wi100" slot="error">
                        <div class="ad-error tal">
                          <div>{{item.serviceName && item.serviceName.length > 16 ? item.serviceName.substr(0,15) + '...' : item.serviceName}}</div>
                        </div>
                      </div>
                    </el-image>
                    <div class="c-box">
                      <div class="f22 c-box-price">
                        <span v-if="item.minPrice">￥{{(item.minPrice || 0).toFixed(2) }}</span>
                        <span v-if="item.minPrice === -1" class="f20">面议</span>
                        <span style="font-size: 5px; color: gray"></span>
                      </div>
                      <div class="dec ctx-link fwb">
                        <span>{{ item.serviceName }}</span>
                      </div>
                      <div style="padding: 0 14px">
                        <div class="f12">
                          <span class="op-07">服务分类：{{ item.itemName }}</span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--                <div v-for="(item, index) in recommendList" :key="index" >-->
                <!--                  <div class="li" v-if="index<5-viewList.length" :key="index">-->
                <!--                    <div class="p-box" @click="initDetail(item.id)" v-if="index<5-viewList.length">-->
                <!--                      <img-->
                <!--                        :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>-->
                <!--                      <div class="c-box" v-if="index<5-viewList.length">-->
                <!--                        <div class="f22 c-box-price">-->
                <!--                          <span v-if="item.minPrice === -1" class="f20">面议</span>-->
                <!--                          <span v-else-if="item.minPrice">￥{{(item.minPrice || 0).toFixed(2) }}</span>-->
                <!--                          <span style="font-size: 5px; color: gray"></span>-->
                <!--                        </div>-->
                <!--                        <div class="dec ctx-link fwb">-->
                <!--                          <span>{{ item.serviceName }}</span>-->
                <!--                        </div>-->
                <!--                        <div style="padding: 0 14px">-->
                <!--                          <div class="f12">-->
                <!--                            <span class="op-07">服务分类：{{ item.itemName }}</span>-->
                <!--                            <span></span>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </template>
              <template v-else>
                <div class="li" v-for="(item, index) in viewList" :key="index">
                  <div class="p-box" @click="initDetail(item.serviceId)">
                    <el-image :src="$cons.SERVER_PATH + item.logoUrl">
                      <div slot="error">
                        <div class="history-error-img">
                          <div>{{item.serviceName && item.serviceName.length > 16 ? item.serviceName.substr(0,15) + "..." : item.serviceName}}</div>
                        </div>
                      </div>
                    </el-image>
                    <!--                    <img-->
                    <!--                      :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>-->
                    <div class="c-box">
                      <div class="f22 c-box-price">
                        <span v-if="item.minPrice === -1" class="f20">面议</span>
                        <span v-else-if="item.minPrice">￥{{(item.minPrice || 0).toFixed(2) }}</span>
                        <span style="font-size: 5px; color: gray"></span>
                      </div>
                      <div class="dec ctx-link fwb">
                        <span>{{ item.serviceName }}</span>
                      </div>
                      <div style="padding: 0 14px">
                        <div class="f12">
                          <span class="op-07">服务分类：{{ item.itemName }}</span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <need-follow-or-add v-if="addOrUpdateVisible" ref="addNeed" @closeCallback="closeCallback"/>
  </div>
</template>
<script>
import NeedFollowOrAdd from '../manage/modules/need/need-follow-or-add'
import ServiceComment from './service-comment'
import {checkIsNull} from '../../utils'

export default {
  name: 'service',
  components: {
    NeedFollowOrAdd,
    ServiceComment
  },
  computed: {
    defaultImg () {
      return require('../../assets/img/product/default_1.png')
    },
    name: {
      get () { return this.$store.state.user.name }
    },
    mobile: {
      get () { return this.$store.state.user.mobile }
    },
    email: {
      get () { return this.$store.state.user.email }
    },
    info: {
      get () { return this.$store.state.user.info }
    },
    //接受路由传参 服务产品channel_item_code获取服务详情
    id() {
      return this.$route.query.id
    },
    //存取用户登录状态
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      }
    },
    userDiscountEntityList: {
      get () { return this.$store.state.user.userDiscountEntityList },
      set (val) { this.$store.commit('user/updateUserDiscountEntityList', val) }
    }
  },
  data() {
    return {
      active:'detail',
      collection: true,
      simulatedDATA: {difference: [], specifications: []},
      attributeList: [],
      attriValueList: [],
      specifications: [],
      selectValue: [],
      assortList: new Map(),
      diffList: [],
      selectArr: [], //存放被选中的值
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      payVisible: true,
      price: '',
      myVar: '',
      discountProductPrice: -1,
      priceVisible: false,
      priceList: {max: 0, min: 0},
      count: 1,
      discount: -1,
      userDiscountId: '',
      addOrUpdateVisible: false,
      dataForm: {},
      containerShow: false,
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      viewList: [],
      attarAreaList: [],
      processList: [],
      descUrlList: [],
      recommendList: [],
      attarArea: [],
      ftime: '',
      priceTypeName: '',
      dataList: [{amount: 10000, comment: 100}],
      tooltipVisible: {},
      labelStyle: {
        textAlign: 'right'
      },
      contentStyle: {
        textAlign: 'center'
      }
    }
  },
  created() {
    this.initDetail(this.id)
  },
  methods: {
    //获取服务详情
    initDetail(id) {
      Object.assign(this.$data, this.$options.data())
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.PUB.SERVICE_INFO + '/' + id),
        method: 'POST'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataForm = data.serviceInfo

          // 价格类型： 每件 等
          this.priceTypeName = this.dataForm.skuList[0].priceTypeName

          // 服务地区
          this.attarAreaList = data.serviceInfo.attributeAreaList

          // if (this.attarAreaList.length > 0 && this.attarAreaList[0].children.length === 0) {
          //   delete this.attarAreaList[0]['children']
          //   this.attarArea.push('全国')
          //   this.specificationBtn('全国', 0, 0)
          // }

          // 数据处理方法
          this.dataProcessing()
          this.checkItem()
          this.processList = this.dataForm.serviceProcess ? this.dataForm.serviceProcess.split(',') : []
          this.descUrlList = this.dataForm.descUrl ? this.dataForm.descUrl.split(',') : []
          this.dataForm.descUrl = this.dataForm.descUrl ? this.dataForm.descUrl : ''
          if (this.loginStatus) {
            this.initCollect()
            this.viewControl()
          }
        }
        this.recommendService()
        this.loading = false
      })
    },
    initCollect() {
      this.$http({
        url: this.$http.adornUrl(this.$api.COLLECT.SEARCH),
        method: 'get',
        params: this.$http.adornParams({
          'serviceId': this.dataForm.id
        })
      }).then(({data}) => {
        if (data) {
          this.collection = false
        }
      })
    },
    collectionControl() {
      if (this.loginStatus) {
        this.$http({
          url: this.$http.adornUrl(this.$api.COLLECT.SAVE),
          method: 'POST',
          data: this.$http.adornData({
            'serviceId': this.dataForm.id,
            'serviceName': this.dataForm.serviceName,
            'itemCode': this.dataForm.itemCode,
            'logoUrl': this.dataForm.itemUrl
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message.success('已收藏')
            this.collection = !this.collection
          }
        })
      } else {
        this.$message.warning('该功能需要登录')
      }
    },
    // 删除
    removeCollect() {
      this.$http({
        url: this.$http.adornUrl(this.$api.COLLECT.REMOVE),
        method: 'post',
        params: this.$http.adornParams({
          'serviceId': this.dataForm.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message.info('已取消收藏')
          this.collection = !this.collection
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    dataProcessing() {
      // 遍历skuList找到最大价格this.priceList.max和最小价格this.priceList.min
      this.dataForm.skuList.forEach((item, index) => {
        if (index === 0) {
          this.priceList.max = item.price
          this.priceList.min = item.price
        } else {
          this.priceList.max = Math.max(this.priceList.max, item.price)
          this.priceList.min = Math.min(this.priceList.min, item.price)
        }

        var abList = []
        var avList = []
        item.priceDesc.split(',').forEach(item => {
          this.attributeList.push(item.split(':')[0])
          this.attriValueList.push(item.split(':')[1])
          abList.push(item.split(':')[0])
          avList.push(item.split(':')[1])
        })

        // diffList： 所有价格组合{id: '', price: '', difference: ''}
        var map = {id: '', price: '', difference: ''}
        map.id = item.id
        map.price = item.price
        var str = []
        for (var i = 0; i < abList.length; i = i + 1) {
          if (abList[i] === '服务地区') {
            str[i] = avList[i].split('|')[1]
          } else {
            str[i] = avList[i]
          }
        }
        map.difference = str.join(',')
        this.diffList.push(map)
      })

      // 折扣信息
      this.userDiscountEntityList.forEach(item => {
        if (item.discountProduct.serviceId === this.id && item.discountProduct.price < this.priceList.min) {
          this.priceList.min = item.discountProduct.price
        }
      })

      // assortList：（key, value）格式，key为type，value为 [] ，包含了所有类型
      for (var j = 0; j < this.attributeList.length; j = j + 1) {
        if (this.assortList.get(this.attributeList[j])) {
          if (this.attributeList[j] === '服务地区') {
            this.assortList.get(this.attributeList[j]).push(this.attriValueList[j].split('|')[1])
          } else {
            this.assortList.get(this.attributeList[j]).push(this.attriValueList[j])
          }
          this.assortList.set(this.attributeList[j], [...new Set(this.assortList.get(this.attributeList[j]))])
        } else {
          this.assortList.set(this.attributeList[j], [])
          if (this.attributeList[j] === '服务地区') {
            this.assortList.get(this.attributeList[j]).push(this.attriValueList[j].split('|')[1])
          } else {
            this.assortList.get(this.attributeList[j]).push(this.attriValueList[j])
          }
          this.assortList.set(this.attributeList[j], [...new Set(this.assortList.get(this.attributeList[j]))])
        }
      }

      this.attributeList = [...new Set(this.attributeList)]
      this.attributeList.forEach(val => {
        this.dataForm.attributeList.forEach(item => {
          if(val === item.attributeName){
            var map = {name: '', item: []}
            map.name = item.attributeName
            var list = item.attributeValue.split(',')
            list.forEach(item => {
              var itemName = {name: ''}
              itemName.name = item
              map.item.push(itemName)
            })
            this.specifications.push(map)
          }
        })
      })


      this.simulatedDATA.difference = this.diffList
      this.simulatedDATA.specifications = this.specifications
      // this.simulatedDATA.specifications = this.simulatedDATA.specifications.filter(i => i.name !== '服务地区')
      for (var i in this.simulatedDATA.difference) {
        this.shopItemInfo[this.simulatedDATA.difference[i].difference] = this.simulatedDATA.difference[i]
      }

      // 默认第一个
      let s = this.diffList[0].difference.split(',')
      s.forEach((item, index) => {
        if(index === 0){
          this.attarArea.push(item)
          this.specificationBtn(item, 0, 0)
        } else {
          this.specificationBtn(item, index, 0)
        }
      })
      // if (this.specifications[0].item.length === 1) {
      //   var area = this.specifications[0].item[0].name.split('|')[1]
      //   this.attarArea.push(area)
      //   console.log(area)
      //   this.specificationBtn(area, 0, 0)
      // }
    },
    addOrFollow() {
      if (this.loginStatus) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs['addNeed'].init({
            serviceId: this.dataForm.id,
            serviceName: this.dataForm.serviceName,
            itemCode: this.dataForm.itemCode,
            itemName: this.dataForm.itemName
          })
        })
      } else {
        this.$message.error('请您登录账号')
        this.$router.push({name: 'login'})
      }
    },
    closeCallback(refresh) {
      this.addOrUpdateVisible = false
    },
    //立即购买
    purchase() {
      if (this.loginStatus) {
        var totalPrice = 0
        var disCount = 0
        if (this.discountProductPrice === -1) {
          totalPrice = this.shopItemInfo[this.selectValue].price * this.count
          disCount = 0
        } else {
          if (this.discount === -1 ) {
            totalPrice = this.discountProductPrice*this.count
            disCount = this.count
          } else {
            totalPrice = this.discount < this.count ? this.discountProductPrice*this.discount + this.shopItemInfo[this.selectValue].price*(this.count-this.discount) : this.discountProductPrice*this.count
            disCount = this.discount < this.count ? this.discount : this.count
          }
        }
        let info = {
          itemCode: this.dataForm.itemCode,
          itemName: this.dataForm.itemName,
          serviceId: this.dataForm.id,
          serviceName: this.dataForm.serviceName,
          skuId: this.shopItemInfo[this.selectValue].id,
          price: this.shopItemInfo[this.selectValue].price,
          quantity: this.count,
          difference: this.shopItemInfo[this.selectValue].difference,
          priceType: this.shopItemInfo[this.selectValue].priceType || null,
          templateFilePath: this.dataForm.templateFilePath ?? null,
          userDiscountId: this.userDiscountId ?? null,
          discountProductPrice: this.discountProductPrice !== -1 ? this.discountProductPrice : 0,
          discount: disCount,
          totalSum: totalPrice,
          contacts: this.name,
          mobile: this.mobile,
          email: this.email,
          company :this.info.length === 1 ? this.info[0].enterpriseName : '无'
        }
        localStorage.setItem('payMentInfo', JSON.stringify(info))
        this.$router.push({
          name: 'payment',
        })
      } else {
        this.$message.error('请您先登录')
        this.$router.push({name: 'login'})
      }
    },
    //加入购物车
    toShoppingCart() {
      if (this.loginStatus) {
        let _this = this
        this.$http({
          url: this.$http.adornUrl(this.$api.SHOPPING_CART.SAVE),
          method: 'POST',
          data: this.$http.adornData({
            'itemCode': this.dataForm.itemCode,
            'serviceId': this.dataForm.id,
            'skuId': this.shopItemInfo[this.selectValue].id,
            'price': this.shopItemInfo[this.selectValue].price,
            'count': this.count
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '成功加入购物车，您可以去购物车结算！',
              type: 'success'
            })
          }
        })
      } else {
        this.$message.error('请您登录账号')
        this.$router.push({name: 'login'})
      }
    },
    goback() {
      //获取缓存
      let itemCode = localStorage.getItem('itemCode')
      // this.$router.go(-1)
      let itemCodeType = this.$route.query.itemCodeType
      if (itemCodeType==='search') {
        this.$router.push({ name: 'search', params: {itemCode: itemCode ? itemCode: null,},meta:{keepAlive: true} })
      }else if (itemCodeType === 'pay') {
        this.$router.push({ name: 'search', params: {itemCode: itemCode ? itemCode: null,},meta:{keepAlive: true} })
      }else{
        this.$router.go(-1)
      }
    },
    handleChange(value) {
      let item = this.$refs.tree.getCheckedNodes()[0].value
      let index = 0
      for (let shopItemInfoKey in this.shopItemInfo) {
        if (item === shopItemInfoKey.split(',')[0]) {
          this.specificationBtn1(item, 0, index)
          break
        }
        index = index + 1
      }

    },
    specificationBtn1(item, n, index) {
      var self = this
      self.selectArr[n] = item
      self.subIndex[n] = index
      self.checkItem()
    },
    specificationBtn(item, n, index) {

      var self = this
      if (self.selectArr[n] != item) {
        self.selectArr[n] = item
        self.subIndex[n] = index
      } else {
        self.selectArr[n] = ''

        self.subIndex[n] = -1 //去掉选中的颜色
      }
      self.checkItem()
    },
    pNameVisible (e, name, index) {
      let thisWidth = e.target.clientWidth
      let wordWidth = e.target.scrollWidth
      if (wordWidth > thisWidth + 5) {
        this.tooltipVisible[name + '-' + index] = true
        this.$forceUpdate()
      } else {
        this.tooltipVisible[name + '-' + index] = false
      }
    },
    checkItem() {
      var self = this
      var option = self.simulatedDATA.specifications
      var result = [] //定义数组储存被选中的值
      for (var i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : ''
      }
      for (var i in option) {
        var last = result[i] //把选中的值存放到字符串last去
        for (var k in option[i].item) {
          result[i] = option[i].item[k].name //赋值，存在直接覆盖，不存在往里面添加name值
          option[i].item[k].isShow = self.isMay(result) //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      this.selectValue = result
      if (this.shopItemInfo[result]) {
        this.discountProductPrice = -1
        this.discount = -1
        this.userDiscountId = ''
        this.ftime = ''
        this.userDiscountEntityList.forEach(item => {
          if (item.discountProduct.skuId === this.shopItemInfo[result].id) {
            this.discountProductPrice = item.discountProduct.price
            this.discount = item.useTimes
            this.userDiscountId = item.id
            this.ftime = item.endTime
          }
        })
        this.price = this.shopItemInfo[result].price || ''
        this.priceVisible = true
        this.payVisible = false
      } else {
        this.price = 0
        this.priceVisible = false
        this.payVisible = true
      }
      self.$forceUpdate() //重绘
    },
    isMay(result) {
      for (var i in result) {
        if (result[i] == '') {
          return true //如果数组里有为空的值，那直接返回true
        }
      }
      return this.shopItemInfo[result] ? true : false
    },
    viewControl() {
      this.$http({
        url: this.$http.adornUrl(this.$api.VIEW.LIST),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': 5
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.viewList = data.page.list
          this.viewSave()
        }
      })
    },
    viewSave() {
      this.$http({
        url: this.$http.adornUrl(this.$api.VIEW.SAVE),
        method: 'POST',
        data: this.$http.adornData({
          'serviceId': this.dataForm.id,
          'serviceName': this.dataForm.serviceName,
          'itemCode': this.dataForm.itemCode,
          'logoUrl': this.dataForm.itemUrl
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
        }
      })
    },
    //猜你喜欢
    recommendService() {
      this.$http({
        url: this.$http.adornUrl(this.$api.PUB.RECOMMEND_LIST),
        method: 'post',
        params: this.$http.adornParams({
          'page': 1,
          'limit': 5
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.recommendList = data.page.list
        } else {
          this.dataList = []
          this.totalPage = 0
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.content-box {
  width: 1325px;
  margin: 20px auto;
  .bs {
    border-radius: 0 10px 10px;
  }
  .company-box {
    width: 100%;
    padding: 10px 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    .icon-box {
      width: 50px;
      height: 50px;
      background: linear-gradient(45deg, #0d9aff, #00d2d9);
      border-radius: 30px;
      color: white;
      .iconfont {
        font-size: 25px;
      }
    }
  }
}
.btns {
  padding: 0 10px 8px 10px;
}

.service-main {
  width: 1268px;
}

.ad-aside {
  padding: 0 24px;
}

.ad-aside .tit {
  text-align: center;
  position: relative;
}

.ad-aside .tit .txt {
  position: relative;
  padding: 5px 8px;
  background: #ffffff;
  z-index: 4;
  font-size: 13px;
}

.ad-list {
  position: relative;
  margin-top: 16px;
  text-align: center;
  width: 210px;
  height: 250px;
  background: #ffffff;
  line-height: 20px;
  border: 1px solid #DCDFE6;
  cursor: pointer;
}
.ad-image {
  width: 208px;
  height: 161px;
}
.ad-error {
  width: 208px;
  height: 161px;
  background-image: url("../../assets/img/product/default_3.png");
  background-size: cover;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 40px 20px;
  color: #FFFFFF;
}

.ad-list img {
  width: 100%;
  height: auto;
}

.ad-list:hover {
  border: 1px solid #409EFF;
}

.detail .label {
  float: left;
  width: 70px;
  height: 40px;
  font-size: 12px;
  opacity: 0.7;
}

.detail .price {
}

.detail .btns {
  padding-left: 90px;
}

.el-tabs__content span {
  padding: 4px 8px;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 12px;
}

.el-tabs__content span:hover {
  color: #ffffff;
  background: #0096e6;
}

.service-details-img {
  margin: auto;
  text-align: center;
  min-height: 900px;
  position: relative;
}
.service-details-img img {
  width: 750px;
  height: 100%;
}
.service-details-img img:after {
  content: url('../../assets/img/product/default-detail.jpg');
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 750px;
  height: 100%;
  background-color: #ffffff;
}
.abc:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.product-footerlist .p-name {
  max-width: 800px;
  border: 1px solid transparent;
  padding: 4px 15px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: #efefef;
  margin-bottom: 10px;
}

.product-footerlist .p-name.productActive {
  border: 1px solid red;
}

.product-footerlist .p-name.noneActive {
  opacity: 0.4;
  pointer-events: none;
  color: gray;
}
.choose:hover {
  cursor: pointer;
  border: 2px solid red;
}

.pic-box {
  width: 100%;
  height: 180px;
  background: #ffffff;
}

.li-box {
  width: 1325px;
  margin: 0 auto;
}

.li-box .li {
  width: 245px;
  height: 320px;
  margin: 6px;
  background: #ffffff;
  line-height: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .08);
}

.li-box .li:hover {
  /*border: 1px solid #409EFF;*/
  box-shadow: 0 8px 15px rgba(0, 0, 0, .15);
  transform: translate3d(0, -2px, 0);
}

.li-box .p-box {
  width: 245px;
  height: 190px;
  cursor: pointer;
}

.li-box .p-box img {
  width: 245px;
  height: 190px;
}
.history-error-img {
  width: 245px;
  height: 190px;
  background-image: url("../../assets/img/product/default_3.png");
  background-size: cover;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 40px 20px;
  color: #FFFFFF;
}

.li-box .c-box .dec {
  padding: 10px 14px 0 14px;
  font-size: 13px;
  overflow: hidden;
  cursor: pointer;
}

.c-link:hover {
  color: #3987dd;
  text-decoration: underline;
  opacity: 1;
}

.c-box-price {
  padding: 10px 14px;
  color: #fe3b25;
}

.li-box .c-box .company {
  padding: 0 12px;
  margin-top: 8px;
}

.areaErrMsg {
  margin-left: 20px;
  color: #ff6a00;
  font-family: PingFangSC-Regular, PingFang SC;
}
.service-image {
  width: 400px;
  height: 280px;
  //border: #f0f0f0 solid 1px;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
}
.error-img-wrapper {
  background-image: url("../../assets/img/product/default_3.png");
  background-size: contain;
  width: 290px;
  height: 210px;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 40px 20px;
  color: #FFFFFF;
}
</style>
